import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Main from  '../components/Main.vue'
import AddPlayer from '../components/AddPlayer.vue'
import Portal from '../components/Portal.vue'
import InfoPla from '../components/InfoPla.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Main',
    name: 'Main',
    component: Main,
    meta:{
      private:true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/addplayer',
    name: 'AddPlayer',
    component: AddPlayer,
    meta:{
      private:true
    }
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    meta:{
      private:true
    }
  },
  {
    path: '/infoplayer',
    name: 'InfoPla',
    component: InfoPla,
    meta:{
      private:true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

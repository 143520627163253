<template>
  <v-container>

    <div class="contenedor">
      <v-card
        class="card-izq"
        width="400"
        height="400"
        outlined
        color="#0061EF"
        @click="goAddPlayer"
      >
        <h1 class="texto">AÑADIR JUGADOR</h1>

        <v-img
              src="../assets/jugador.png"
              class="white--text align-end"
              height="350px"
            />
      </v-card>


     <v-card
        class="card-dch"
        width="400"
        height="400"
        outlined
        color="#00D590"
        @click="goPortal"
      >
        <h1 class="texto">ACCEDER PORTAL</h1>

        <v-img
              src="../assets/adm.png"
              class="white--text align-end"
              height="350px"
              width="400"
            />
      </v-card>

    </div>

  </v-container>
</template>

<script>

  export default {
    name: 'Main',

    data: () => ({
      
    }),
    mounted(){
      
    },
    methods: {
      goAddPlayer(){
         this.$router.push({ name: "AddPlayer"})
      },
      goPortal(){
         this.$router.push({ name: "Portal"})
      },
    }
  }
</script>
<style scoped lang="scss">
.contenedor {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-izq{
  margin-right: 20px;
}

.card-dch{
  margin-left: 20px;
}

.texto{
  color: white;
  text-align: center;
}
</style>
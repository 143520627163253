<template>
  <v-container>
    <div class="my-2">
            <v-btn
              color="error"
              dark
              large
              @click="volver()"
            >
              Volver
            </v-btn>
          </div>
    <div class="contenedor2">

      

        <h1 class="center1">MILLENNIUM FALCON</h1>
        
    
        <p> Filtrar por jugador:</p>

        <v-row>
        <v-col
            class="d-flex"
            cols="12"
            sm="6"
        >
            <v-select
            :items="player_name"
            label="Jugador"
            dense
            v-model="jugador"
            solo
            ></v-select>
        </v-col>
        </v-row>  
    
    
        <p> Filtrar por temporada:</p>

        <v-row>
        <v-col
            class="d-flex"
            cols="12"
            sm="6"
        >
            <v-select
            :items="seasons"
            label="Temporada"
            dense
            v-model="temporada"
            solo
            ></v-select>
        </v-col>
        </v-row> 
           
      

       <div class="contenedor3">
            <v-btn
              color="info"
              dark
              large
              class="boton"
              @click="seleccionarjugador()"
            >
              Buscar
            </v-btn>  

            <v-btn
              color="error"
              dark
              large
              class="boton1"
              @click="cancelar()"
            >
              CANCELAR
            </v-btn>  

            <v-btn
              color="#FF9800"
              dark
              large
              class="boton1"
              @click="seleccionarpendiente()"
            >
              PENDIENTES
            </v-btn>  

        </div>
    
    </div>

    <v-layout v-if="this.unico==0" wrap justify-space-around>
    <v-flex v-for="image in this.jugadores" :key="image.NOMBRE">
        <v-card
        width="500px"
        height="200px"
        outlined
        class="carta"
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="text-overline mb-4">
            Jugador {{image.DISPONIBILIDAD}}
            </div>
            <v-list-item-title class="text-h5 mb-1">
            {{ image.NOMBRE }}
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-avatar
            tile
            size="80"
        
        >
        <v-img  :src="image.FOTO"></v-img>
        </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
        <v-btn
            outlined
            rounded
            text
            @click="goInfo(image)"
        >
            ESTADO:  {{ image.PORCENTAJE }} %
        </v-btn>
        <v-icon
            v-if="image.TODO==1"
            color="error"
        >
            mdi-exclamation-thick
        </v-icon>
        </v-list-item>
    </v-card>
    </v-flex>
    </v-layout>

    <v-layout v-if="this.unico==1" wrap justify-space-around>
        <v-card
        width="500px"
        height="200px"
        outlined
        class="carta"
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="text-overline mb-4">
            Jugador {{this.actual_play.DISPONIBILIDAD}}
            </div>
            <v-list-item-title class="text-h5 mb-1">
            {{ this.actual_play.NOMBRE }}
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-avatar
            tile
            size="80"
        
        >
        <v-img  :src="this.actual_play.FOTO"></v-img>
        </v-list-item-avatar>
        </v-list-item>

        <v-card-actions class="contenedor4">
        <v-btn
            outlined
            rounded
            text
            @click="goInfo(image)"
        >
            ESTADO:  {{ this.actual_play.PORCENTAJE }} %
        </v-btn>
        <p v-if="this.actual_play.TODO==1">PENDIENTE</p>
        </v-card-actions>
    </v-card>
    </v-layout>

    <v-layout v-if="this.unico==2" wrap justify-space-around>
    <v-flex v-for="image in this.player_pendiente" :key="image.NOMBRE">
        <v-card
        width="500px"
        height="200px"
        outlined
        class="carta"
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="text-overline mb-4">
            Jugador {{image.DISPONIBILIDAD}}
            </div>
            <v-list-item-title class="text-h5 mb-1">
            {{ image.NOMBRE }}
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-avatar
            tile
            size="80"
        
        >
        <v-img  :src="image.FOTO"></v-img>
        </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
        <v-btn
            outlined
            rounded
            text
            @click="goInfo(image)"
        >
            ESTADO:  {{ image.PORCENTAJE }} %
        </v-btn>
        <v-icon
            v-if="image.TODO==1"
            color="error"
        >
            mdi-exclamation-thick
        </v-icon>
        </v-list-item>
    </v-card>
    </v-flex>
    </v-layout>

    <v-layout v-if="this.unico==3" wrap justify-space-around>
    <v-flex v-for="image in this.player_temporada" :key="image.NOMBRE">
        <v-card
        width="500px"
        height="200px"
        outlined
        class="carta"
    >
        <v-list-item three-line>
        <v-list-item-content>
            <div class="text-overline mb-4">
            Jugador {{image.DISPONIBILIDAD}}
            </div>
            <v-list-item-title class="text-h5 mb-1">
            {{ image.NOMBRE }}
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-avatar
            tile
            size="80"
        
        >
        <v-img  :src="image.FOTO"></v-img>
        </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
        <v-btn
            outlined
            rounded
            text
            @click="goInfo(image)"
        >
            ESTADO:  {{ image.PORCENTAJE }} %
        </v-btn>
        <v-icon
            v-if="image.TODO==1"
            color="error"
        >
            mdi-exclamation-thick
        </v-icon>
        </v-list-item>
    </v-card>
    </v-flex>
    </v-layout>


  </v-container>
</template>

<script>
const { GoogleSpreadsheet } = require('google-spreadsheet');

  export default {
    name: 'Main',

    data: () => ({
      jugadores: [],
      jugadores_copia: [],
      uni_name: [],
      player_name:[],
      jugador:'',
      filter_player:[],
      actual_play:[],
      player_pendiente:[],
      player_temporada:[],
      temporada:'',
      seasons: ['2022','2023','2024','2025','2026','2027'],
      unico:0,
    }),
    mounted(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        this.start(doc,creds);
    },
    methods: {
     volver(){
        this.$router.push({ name: "Main"})
      },
      cancelar(){
        this.actual_play=[];
        this.actual_uni=[];
        this.jugador='';
        this.temporada='';
        this.jugadores=this.jugadores_copia;
        this.player_temporada=[];
        this.player_pendiente=[];
        this.unico=0;

      },  
      goInfo(player){
        this.$router.push({ name: "InfoPla", params: {jugador: player}})
      },
      seleccionarjugador(){
        if(this.jugador==''){
            var players = [];
            for(var y=0;y<this.jugadores_copia.length;y++){
                if(this.temporada==this.jugadores_copia[y].DISPONIBILIDAD){
                    players.push(this.jugadores_copia[y]);
                }
            }
            this.player_temporada=players;
            this.unico=3;
        }    
        if(this.jugador!=''){
            var player = "";
            for(var x=0;x<this.jugadores_copia.length;x++){
                if(this.jugador==this.jugadores_copia[x].NOMBRE){
                    player = this.jugadores_copia[x];
                }
            }
            this.actual_play=player;
            this.unico=1;
        }
      },
      seleccionarpendiente(){
        var players = [];
        for(var x=0;x<this.jugadores_copia.length;x++){
          if(1==this.jugadores_copia[x].TODO){
            players.push(this.jugadores_copia[x]);
          }
        }
        this.player_pendiente=players;
        this.unico=2;
      },
      async start(doc,creds){
        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }
        this.jugadores = rows;

        for (let i = 0; i < this.jugadores.length; i++){
            this.player_name.push(this.jugadores[i].NOMBRE);
        }

        this.player_name = this.player_name.sort();


        for(var x=0;x<this.jugadores.length;x++){
          this.jugador=this.jugadores[x].NOMBRE;
          var porcentaje = this.calculaporcentaje(this.jugadores[x]);
          var todo = this.calculatodo(this.jugadores[x]);
          this.jugadores[x].PORCENTAJE = porcentaje;
          this.jugadores[x].TODO = todo;
          ////////cambiar
          this.jugadores[x].FOTO = "https://pbs.twimg.com/profile_images/1307950825894023168/ebHwGvxv_400x400.jpg";
        }

        this.filter_player=[];
        this.jugador="";

        this.jugadores_copia=this.jugadores;
        
      },
      calculatodo(jugador){
        var todo = 0;
        if (jugador.PASAPORTE==2){
            todo=1;
            }
        if (jugador.NOTAS_3_ESO==2){
            todo=1;
        }
        if (jugador.NOTAS_4_ESO==2){
            todo=1;
        }
        if (jugador.NOTAS_1_BACH==2){
            todo=1;
        }
        if (jugador.NOTAS_2_BACH==2){
            todo=1;
        }
        if (jugador.TITULO_BACHILLERATO==2){
            todo=1;
        }
        if (jugador.CARTA_DEL_BANCO==2){
            todo=1;
        }
        if (jugador.REGISTRO_LIGA==2){
            todo=1;
        }
        if (jugador.REGISTRO_UNIVERSIDAD==2){
            todo=1;
        }
        if (jugador.DUOLINGO==2){
            todo=1;
        }
        if (jugador.TOEFL==2){
            todo=1;
        }
        if (jugador.SAT==2){
            todo=1;
        }
        if (jugador.PARTIDOS_COMPLETOS_2==2){
            todo=1;
        }
        if (jugador.I20==2){
            todo=1;
        }
        if (jugador.FORMULARIO_ADM==2){
            todo=1;
        }
        if (jugador.INCRED==2){
            todo=1;
        }
        if (jugador.EVALUACION_DE_LAS_NOTAS==2){
            todo=1;
        }
        if (jugador.NOTAS_CARRERA==2){
            todo=1;
        }
        if (jugador.REFERENCE_LETTER==2){
            todo=1;
        }
        if (jugador.SEGURO_MEDICO==2){
            todo=1;
        }
        if (jugador.VUELOS==2){
            todo=1;
        }
        if (jugador.HOUSING==2){
            todo=1;
        }

        return todo;
      },
      calculaporcentaje(jugador){
            var cuenta = 0;
            var correcto = 0;
            if (jugador.PASAPORTE!=0){
                cuenta=cuenta+1;
                if (jugador.PASAPORTE==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_3_ESO!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_3_ESO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_4_ESO!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_4_ESO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_1_BACH!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_1_BACH==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_2_BACH!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_2_BACH==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.TITULO_BACHILLERATO!=0){
                cuenta=cuenta+1;
                if (jugador.TITULO_BACHILLERATO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.CARTA_DEL_BANCO!=0){
                cuenta=cuenta+1;
                if (jugador.CARTA_DEL_BANCO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REGISTRO_LIGA!=0){
                cuenta=cuenta+1;
                if (jugador.REGISTRO_LIGA==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REGISTRO_UNIVERSIDAD!=0){
                cuenta=cuenta+1;
                if (jugador.REGISTRO_UNIVERSIDAD==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.DUOLINGO!=0){
                cuenta=cuenta+1;
                if (jugador.DUOLINGO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.TOEFL!=0){
                cuenta=cuenta+1;
                if (jugador.TOEFL==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.SAT!=0){
                cuenta=cuenta+1;
                if (jugador.SAT==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.PARTIDOS_COMPLETOS_2!=0){
                cuenta=cuenta+1;
                if (jugador.PARTIDOS_COMPLETOS_2==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.I20!=0){
                cuenta=cuenta+1;
                if (jugador.I20==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.FORMULARIO_ADM!=0){
                cuenta=cuenta+1;
                if (jugador.FORMULARIO_ADM==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.INCRED!=0){
                cuenta=cuenta+1;
                if (jugador.INCRED==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.EVALUACION_DE_LAS_NOTAS!=0){
                cuenta=cuenta+1;
                if (jugador.EVALUACION_DE_LAS_NOTAS==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_CARRERA!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_CARRERA==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REFERENCE_LETTER!=0){
                cuenta=cuenta+1;
                if (jugador.REFERENCE_LETTER==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.SEGURO_MEDICO!=0){
                cuenta=cuenta+1;
                if (jugador.SEGURO_MEDICO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.VUELOS!=0){
                cuenta=cuenta+1;
                if (jugador.VUELOS==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.HOUSING!=0){
                cuenta=cuenta+1;
                if (jugador.HOUSING==3){
                    correcto=correcto+1;
                }
            }

            if (correcto==0){
                return 0;
            }else{
            return parseInt((correcto/cuenta)*100);
            }
        }
    }
  }
</script>
<style scoped lang="scss">
.contenedor2{
  margin-left: 35%;
}

.contenedor3{
  display: flex;
}

.contenedor4{
  height: 50px;
}

.boton1{
    margin-left: 2%;
}

.izquierda{
  flex: 1;
}

.derecha{
  flex: 1;
}

.card{
  float: right;
  margin-right: 10px;
}

.card1{
  margin-left: 10px;
}


.boton{
  margin-bottom: 10px;
}

.selector{
  margin-bottom: 15px;
}

.item{
  margin-top: 15px;
}

.linear{
  display: inline-block;
  padding: 10px;
}

.linear1{
  display: inline-block;
}

.flex {
  flex-grow: 0;
}


.center1{
  margin-left: 10%;
  margin-bottom: 3%;
}


.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  
  div {
    width: 150px;
  }
}

.carta{
    margin: 10px;
}
</style>
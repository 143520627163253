<template>
  <v-container>
    <div class="my-2">
            <v-btn
              color="error"
              dark
              large
              @click="volver()"
            >
              Volver
            </v-btn>
          </div>
    <h1 class="center1">AÑADIR JUGADOR</h1>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="name"
          :rules="[v => !!v || 'Item is required']"
          label="Nombre y Apellidos"
          required
        ></v-text-field>

         <v-text-field
          v-model="mail"
          :rules="[v => !!v || 'Item is required']"
          label="Email"
          required
        ></v-text-field>

        <v-select
          v-model="year"
          :items="items"
          :rules="[v => !!v || 'Item is required']"
          label="Disponibilidad"
          required
        ></v-select>

        <v-file-input
          accept="image/*"
          label="Foto Jugador"
          v-model="foto"
        ></v-file-input>

        <v-card flat>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Pasaporte"
                    color="info"
                    value="pasaporte"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Notas 3 ESO"
                    color="info"
                    value="terceroeso"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Notas 4 ESO"
                    color="info"
                    value="cuartoeso"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Notas 1 Bachillerato"
                    color="info"
                    value="primerobach"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Notas 2 Bachillerato"
                    color="info"
                    value="segundobach"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Titulo Bachillerato"
                    color="info"
                    value="titulobach"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Carta del Banco"
                    color="info"
                    value="banco"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Registro Liga (NCAA o NAIA)"
                    color="info"
                    value="registroliga"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="mt-12">
          
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Registro Universidad"
                    color="info"
                    value="registrouni"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Duolingo"
                    color="info"
                    value="duolingo"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="TOEFL"
                    color="info"
                    value="toefl"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="SAT"
                    color="info"
                    value="sat"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="2 Partidos Completos"
                    color="info"
                    value="dospartidos"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Cortes de Partidos"
                    color="info"
                    value="cortespartidos"
                    hide-details
                  ></v-checkbox>
                </v-col>
                 <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="i20"
                    color="info"
                    value="i20"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Formulario ADM"
                    color="info"
                    value="admform"
                    hide-details
                  ></v-checkbox>
                </v-col>
          
              </v-row>

              <v-row class="mt-12">
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Incred"
                    color="info"
                    value="incred"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Evaluación de las notas"
                    color="info"
                    value="credentialevaluation"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-checkbox
                    v-model="ex4"
                    label="Notas de la Carrera"
                    color="info"
                    value="carrera"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ex4"
                    label="Reference Letter"
                    color="info"
                    value="reference"
                    hide-details
                  ></v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-checkbox
                      v-model="ex4"
                      label="Seguro Médico"
                      color="info"
                      value="seguromedico"
                      hide-details
                    ></v-checkbox>

                    <v-checkbox
                      v-model="ex4"
                      label="Vuelos"
                      color="info"
                      value="vuelos"
                      hide-details
                    ></v-checkbox>
          
                  </v-col>

                   <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-checkbox
                      v-model="ex4"
                      label="Housing"
                      color="info"
                      value="housing"
                      hide-details
                    ></v-checkbox>

                
          
                  </v-col>


              </v-row>
            </v-container>
          </v-card-text>
        </v-card>


        <v-checkbox
          v-model="checkbox"
          :rules="[v => !!v || 'You must agree to continue!']"
          label="Do you agree?"
          required
        ></v-checkbox>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Validate
        </v-btn>

        <v-btn
          color="error"
          class="mr-4"
          @click="reset"
        >
          Reset Form
        </v-btn>

      </v-form>
       
  </v-container>
</template>

<script>
const { GoogleSpreadsheet } = require('google-spreadsheet');
const axios = require('axios');


  export default {
    name: 'Info',
    data: () => ({
      ex4: ['seguromedico', 'reference', 'carrera', 'credentialevaluation', 'incred', 'admform', 'i20', 'cortespartidos', 'dospartidos', 'sat', 'toefl', 'duolingo', 'registrouni', 'registroliga', 'banco', 'titulobach', 'segundobach', 'primerobach', 'cuartoeso', 'terceroeso', 'pasaporte', 'vuelos', 'housing'],
      valid: true,
      name: '',
      mail: '',
      year: '',
      foto: '',
      foto1: 0,
      pasaporte:0,
      terceroeso:0,
      cuartoeso:0,
      primerobach:0,
      segundobach:0,
      titulobach:0,
      banco:0,
      registroliga:0,
      registrouni:0,
      duolingo:0,
      toefl:0,
      sat:0,
      dospartidos:0,
      cortespartidos:0,
      i20:0,
      admform:0,
      incred:0,
      credentialevaluation:0,
      carrera:0,
      reference:0,
      seguromedico:0,
      vuelos:0,
      housing:0,
      select: null,
      items: [
        '2023',
        '2024',
        '2025',
        '2026',
        '2027'
      ],
      checkbox: false,
    }),
    mounted(){
      
    },
    methods: {
      volver(){
        this.$router.push({ name: "Main"})
      },
      validate () {     
         var password = Math.random().toString(36).slice(-8);
         const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
         
         const creds = require('../../public/service_account.json'); // the file saved above

         this.start(doc,creds,password);
      },
      reset () {
        this.$refs.form.reset()
      },
      async start(doc,creds,password){

        var url1 = "https://t4t.infor.uva.es/email?mail="+this.mail+"&pass="+password+"&name="+this.name;

        axios
          .get(url1)
          .then(res => {
            console.log(res)
          })
          .catch(error => {
            console.error(error);
          });
        
        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        const sheet1 = doc.sheetsByIndex[1];

        sheet1.addRow([this.name,this.mail,password]);
        //const rows = await sheet.getRows(); // can pass in { limit, offset }
        if(this.foto != ''){
          this.foto1=1;
        }
        if(this.ex4.includes("seguromedico")){
          this.seguromedico=1;
        }
        if(this.ex4.includes("reference")){
          this.reference=1;
        }
        if(this.ex4.includes("carrera")){
          this.carrera=1;
        }
        if(this.ex4.includes("credentialevaluation")){
          this.credentialevaluation=1;
        }
        if(this.ex4.includes("incred")){
          this.incred=1;
        }
        if(this.ex4.includes("admform")){
          this.admform=1;
        }
        if(this.ex4.includes("i20")){
          this.i20=1;
        }
        if(this.ex4.includes("cortespartidos")){
          this.cortespartidos=1;
        }
        if(this.ex4.includes("dospartidos")){
          this.dospartidos=1;
        }
        if(this.ex4.includes("sat")){
          this.sat=1;
        }
        if(this.ex4.includes("toefl")){
          this.toefl=1;
        }
        if(this.ex4.includes("duolingo")){
          this.duolingo=1;
        }
        if(this.ex4.includes("registrouni")){
          this.registrouni=1;
        }
        if(this.ex4.includes("registroliga")){
          this.registroliga=1;
        }
        if(this.ex4.includes("banco")){
          this.banco=1;
        }
        if(this.ex4.includes("titulobach")){
          this.titulobach=1;
        }
        if(this.ex4.includes("segundobach")){
          this.segundobach=1;
        }
        if(this.ex4.includes("primerobach")){
          this.primerobach=1;
        }
        if(this.ex4.includes("cuartoeso")){
          this.cuartoeso=1;
        }
        if(this.ex4.includes("terceroeso")){
          this.terceroeso=1;
        }
        if(this.ex4.includes("pasaporte")){
          this.pasaporte=1;
        }
        if(this.ex4.includes("vuelos")){
          this.vuelos=1;
        }
        if(this.ex4.includes("housing")){
          this.housing=1;
        }
        sheet.addRow([this.name, this.mail, this.year, this.foto1, this.pasaporte,this.terceroeso,this.cuartoeso,this.primerobach,this.segundobach,this.titulobach,this.banco,this.registroliga,this.registrouni,this.duolingo,this.toefl,this.sat,this.dospartidos,this.cortespartidos,this.i20,this.admform,this.incred,this.credentialevaluation,this.carrera,this.reference,this.seguromedico,this.vuelos,this.housing,]);

        var url = "https://t4t.infor.uva.es/drive?name="+this.name+"&temp="+this.year;
        
        axios
          .get(url)
          .then(res => {
            console.log(res)
          })
          .catch(error => {
            console.error(error);
          });

        
        
        try{
        var formData = new FormData();
        formData.append('file',this.foto,this.foto.name);
        let res = await axios.post('https://t4t.infor.uva.es/guardar_foto?name='+this.name, formData);
        console.log(res)
        }catch(err){
          console.log(err);
        }

        this.$notify({
          group: 'foo',
          title: 'USUARIO CREADO CORRECTAMENTE',
          text: 'Se ha mandado el correo de bienvenida a '+this.name+'. Además de crear todo su contenido asociado en DRIVE.'
        });
        this.$router.push({ name: "Main"})
      },
    }
  }
</script>
<style scoped lang="scss">

.center1{
  margin-left: 43%;
}

</style>
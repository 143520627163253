<template>
  <v-container>
    <div class="my-2">
            <v-btn
              color="error"
              dark
              large
              @click="volver()"
            >
              Volver
            </v-btn>
          </div>
     <v-layout wrap justify-space-around>
        <v-flex>
           <v-card
                width="500px"
                height="200px"
                outlined
                class="carta"
            >
                <v-list-item three-line>
                <v-list-item-content>
                    <div class="text-overline mb-4">
                    Jugador
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                    {{ this.jugador.NOMBRE }}
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="80"
                
                >
                <v-img  :src="this.jugador.FOTO"></v-img>
                </v-list-item-avatar>
                </v-list-item>

                <v-card-actions class="contenedor4">
                <v-btn
                    outlined
                    rounded
                    text
                >
                    ESTADO:  {{ this.jugador.PORCENTAJE }} %
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
         <v-btn
              color="#FF9800"
              dark
              large
              @click="reclamar()"
            >
              Reclamar
            </v-btn>

      </v-layout>
    
      <v-card max-width="95%" class="mx-auto">
        <v-list>
          <v-expansion-panels accordion>
            <!--PASAPORTE-->
            <v-expansion-panel v-if="this.jugador.PASAPORTE!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.PASAPORTE==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PASAPORTE==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PASAPORTE==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>PASAPORTE</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.PASAPORTE==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_pasaporte()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_pasaporte()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--NOTAS 3 ESO-->
            <v-expansion-panel v-if="this.jugador.NOTAS_3_ESO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_3_ESO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_3_ESO==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_3_ESO==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 3 ESO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_3_ESO==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_3eso()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_3eso()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS 4 ESO-->
            <v-expansion-panel v-if="this.jugador.NOTAS_4_ESO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_4_ESO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_4_ESO==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_4_ESO==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 4 ESO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_4_ESO==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_4eso()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_4eso()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS 1 BACH-->
            <v-expansion-panel v-if="this.jugador.NOTAS_1_BACH!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_1_BACH==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_1_BACH==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_1_BACH==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 1 BACHILLERATO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_1_BACH==2"> 
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_1bach()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_1bach()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS 2 BACH-->
            <v-expansion-panel v-if="this.jugador.NOTAS_2_BACH!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_2_BACH==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_2_BACH==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_2_BACH==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 2 BACHILLERATO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_2_BACH==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_2bach()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_2bach()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--TITULO BACHILLERATO-->
            <v-expansion-panel v-if="this.jugador.TITULO_BACHILLERATO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.TITULO_BACHILLERATO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TITULO_BACHILLERATO==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TITULO_BACHILLERATO==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>TITULO BACHILLERATO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.TITULO_BACHILLERATO==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_titulobach()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_titulobach()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--CARTA DEL BANCO-->
            <v-expansion-panel v-if="this.jugador.CARTA_DEL_BANCO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.CARTA_DEL_BANCO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CARTA_DEL_BANCO==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CARTA_DEL_BANCO==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>CARTA DEL BANCO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.CARTA_DEL_BANCO==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_cartabanco()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_cartabanco()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--REGISTRO LIGA-->
            <v-expansion-panel v-if="this.jugador.REGISTRO_LIGA!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_LIGA==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_LIGA==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_LIGA==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>REGISTRO LIGA</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.REGISTRO_LIGA==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_registroliga()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_registroliga()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--REGISTROS UNIVERSIDAD-->
            <v-expansion-panel v-if="this.jugador.REGISTRO_UNIVERSIDAD!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_UNIVERSIDAD==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_UNIVERSIDAD==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_UNIVERSIDAD==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>REGISTRO UNIVERSIDAD</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.REGISTRO_UNIVERSIDAD==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_registrouni()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_registrouni()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--DUOLINGO-->
            <v-expansion-panel v-if="this.jugador.DUOLINGO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.DUOLINGO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.DUOLINGO==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.DUOLINGO==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>DUOLINGO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.DUOLINGO==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_duolingo()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_duolingo()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--TOEFL-->
            <v-expansion-panel v-if="this.jugador.TOEFL!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.TOEFL==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TOEFL==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TOEFL==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>TOEFL</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.TOEFL==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_toefl()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_toefl()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--SAT-->
            <v-expansion-panel v-if="this.jugador.SAT!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.SAT==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SAT==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SAT==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>SAT</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.SAT==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_sat()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_sat()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--PARTIDOS COMPLETOS 2-->
            <v-expansion-panel v-if="this.jugador.PARTIDOS_COMPLETOS_2!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.PARTIDOS_COMPLETOS_2==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PARTIDOS_COMPLETOS_2==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PARTIDOS_COMPLETOS_2==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>2 PARTIDOS COMPLETOS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.PARTIDOS_COMPLETOS_2==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_2partidos()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_2partidos()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content >
            </v-expansion-panel>
             <!--CORTES PARTIDOS-->
            <v-expansion-panel v-if="this.jugador.CORTES_PARTIDOS!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.CORTES_PARTIDOS==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CORTES_PARTIDOS==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CORTES_PARTIDOS==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>CORTES PARTIDOS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.CORTES_PARTIDOS==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_cortespartidos()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_cortespartidos()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--I20-->
            <v-expansion-panel v-if="this.jugador.I20!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.I20==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.I20==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.I20==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>I20</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.I20!=0">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_i20()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_i20()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--FORMULARIO ADM-->
            <v-expansion-panel v-if="this.jugador.FORMULARIO_ADM!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.FORMULARIO_ADM==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.FORMULARIO_ADM==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.FORMULARIO_ADM==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>FORMULARIO ADM</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.FORMULARIO_ADM==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_formularioadm()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_formularioadm()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--INCRED-->
            <v-expansion-panel v-if="this.jugador.INCRED!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.INCRED==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.INCRED==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.INCRED==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>INCRED</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.INCRED==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_incred()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_incred()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--EVALUACIÓN DE LAS NOTAS-->
            <v-expansion-panel v-if="this.jugador.EVALUACION_DE_LAS_NOTAS!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>EVALUACIÓN DE LAS NOTAS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_evaluacionnotas()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_evaluacionnotas()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS CARRERA-->
            <v-expansion-panel v-if="this.jugador.NOTAS_CARRERA!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_CARRERA==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_CARRERA==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_CARRERA==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS CARRERA</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_CARRERA==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_notascarrera()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_notascarrera()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--REFERENCE LETTER-->
            <v-expansion-panel v-if="this.jugador.REFERENCE_LETTER!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.REFERENCE_LETTER==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REFERENCE_LETTER==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REFERENCE_LETTER==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>REFERENCE LETTER</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.REFERENCE_LETTER==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_referenceletter()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_referenceletter()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--SEGURO MEDICO-->
            <v-expansion-panel v-if="this.jugador.SEGURO_MEDICO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.SEGURO_MEDICO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SEGURO_MEDICO==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SEGURO_MEDICO==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>SEGURO MÉDICO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.SEGURO_MEDICO==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_seguromedico()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_seguromedico()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--VUELOS-->
            <v-expansion-panel v-if="this.jugador.VUELOS!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.VUELOS==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.VUELOS==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.VUELOS==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>VUELOS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.VUELOS==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_vuelos()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_vuelos()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--HOUSING-->
            <v-expansion-panel v-if="this.jugador.HOUSING!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.HOUSING==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.HOUSING==2"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.HOUSING==1"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>HOUSING</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.HOUSING==2">
                <div class="contenedor3">
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_housing()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  <v-btn
                    color="error"
                    dark
                    large
                    class="boton1"
                    @click="rechazar_housing()"
                  >
                    RECHAZAR
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>


          </v-expansion-panels>
        </v-list>
      </v-card>
       
  </v-container>
</template>

<script>
const { GoogleSpreadsheet } = require('google-spreadsheet');
const axios = require('axios');
  export default {
    name: 'Info',
    data: () => ({
      jugador: "",
    }),
    mounted(){
      this.jugador=this.$route.params.jugador;
    },
    methods: {
      volver(){
        this.$router.push({ name: "Portal"})
      },
      async confirmar_pasaporte(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].PASAPORTE=3;
              await rows[i].save();
              this.jugador.PASAPORTE=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_3eso(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_3_ESO=3;
              await rows[i].save();
              this.jugador.NOTAS_3_ESO=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_4eso(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_4_ESO=3;
              await rows[i].save();
              this.jugador.NOTAS_4_ESO=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_1bach(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_1_BACH=3;
              await rows[i].save();
              this.jugador.NOTAS_1_BACH=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_2bach(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_2_BACH=3;
              await rows[i].save();
              this.jugador.NOTAS_2_BACH=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_titulobach(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].TITULO_BACHILLERATO=3;
              await rows[i].save();
              this.jugador.TITULO_BACHILLERATO=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_cartabanco(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].CARTA_DEL_BANCO=3;
              await rows[i].save();
              this.jugador.CARTA_DEL_BANCO=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_registroliga(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REGISTRO_LIGA=3;
              await rows[i].save();
              this.jugador.REGISTRO_LIGA=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_registrouni(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REGISTRO_UNIVERSIDAD=3;
              await rows[i].save();
              this.jugador.REGISTRO_UNIVERSIDAD=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_duolingo(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].DUOLINGO=3;
              await rows[i].save();
              this.jugador.DUOLINGO=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_toefl(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].TOEFL=3;
              await rows[i].save();
              this.jugador.TOEFL=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_sat(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].SAT=3;
              await rows[i].save();
              this.jugador.SAT=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_2partidos(){
         const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].PARTIDOS_COMPLETOS_2=3;
              await rows[i].save();
              this.jugador.PARTIDOS_COMPLETOS_2=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_cortespartidos(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].CORTES_PARTIDOS=3;
              await rows[i].save();
              this.jugador.CORTES_PARTIDOS=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_i20(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].I20=3;
              await rows[i].save();
              this.jugador.I20=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_formularioadm(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].FORMULARIO_ADM=3;
              await rows[i].save();
              this.jugador.FORMULARIO_ADM=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_incred(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].INCRED=3;
              await rows[i].save();
              this.jugador.INCRED=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_evaluacionnotas(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].EVALUACION_DE_LAS_NOTAS=3;
              await rows[i].save();
              this.jugador.EVALUACION_DE_LAS_NOTAS=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_notascarrera(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_CARRERA=3;
              await rows[i].save();
              this.jugador.NOTAS_CARRERA=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_referenceletter(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REFERENCE_LETTER=3;
              await rows[i].save();
              this.jugador.REFERENCE_LETTER=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_seguromedico(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].SEGURO_MEDICO=3;
              await rows[i].save();
              this.jugador.SEGURO_MEDICO=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_vuelos(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].VUELOS=3;
              await rows[i].save();
              this.jugador.VUELOS=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async confirmar_housing(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].HOUSING=3;
              await rows[i].save();
              this.jugador.HOUSING=3;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN CONFIRMADA CON ÉXITO',
              text: 'Se ha confirmado la información de '+this.jugador.NOMBRE+"."
            });
      },
      async rechazar_pasaporte(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].PASAPORTE=1;
              await rows[i].save();
              this.jugador.PASAPORTE=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_3eso(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_3_ESO=1;
              await rows[i].save();
              this.jugador.NOTAS_3_ESO=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_4eso(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_4_ESO=1;
              await rows[i].save();
              this.jugador.NOTAS_4_ESO=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_1bach(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_1_BACH=1;
              await rows[i].save();
              this.jugador.NOTAS_1_BACH=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_2bach(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_2_BACH=1;
              await rows[i].save();
              this.jugador.NOTAS_2_BACH=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_titulobach(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].TITULO_BACHILLERATO=1;
              await rows[i].save();
              this.jugador.TITULO_BACHILLERATO=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_cartabanco(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].CARTA_DEL_BANCO=1;
              await rows[i].save();
              this.jugador.CARTA_DEL_BANCO=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_registroliga(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REGISTRO_LIGA=1;
              await rows[i].save();
              this.jugador.REGISTRO_LIGA=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_registrouni(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REGISTRO_UNIVERSIDAD=1;
              await rows[i].save();
              this.jugador.REGISTRO_UNIVERSIDAD=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_duolingo(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].DUOLINGO=1;
              await rows[i].save();
              this.jugador.DUOLINGO=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_toefl(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].TOEFL=1;
              await rows[i].save();
              this.jugador.TOEFL=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_sat(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].SAT=1;
              await rows[i].save();
              this.jugador.SAT=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_2partidos(){
         const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].PARTIDOS_COMPLETOS_2=1;
              await rows[i].save();
              this.jugador.PARTIDOS_COMPLETOS_2=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_cortespartidos(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].CORTES_PARTIDOS=1;
              await rows[i].save();
              this.jugador.CORTES_PARTIDOS=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_i20(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].I20=1;
              await rows[i].save();
              this.jugador.I20=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_formularioadm(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].FORMULARIO_ADM=1;
              await rows[i].save();
              this.jugador.FORMULARIO_ADM=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_incred(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].INCRED=1;
              await rows[i].save();
              this.jugador.INCRED=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_evaluacionnotas(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].EVALUACION_DE_LAS_NOTAS=1;
              await rows[i].save();
              this.jugador.EVALUACION_DE_LAS_NOTAS=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_notascarrera(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_CARRERA=1;
              await rows[i].save();
              this.jugador.NOTAS_CARRERA=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_referenceletter(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REFERENCE_LETTER=1;
              await rows[i].save();
              this.jugador.REFERENCE_LETTER=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_seguromedico(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].SEGURO_MEDICO=1;
              await rows[i].save();
              this.jugador.SEGURO_MEDICO=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_vuelos(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].VUELOS=1;
              await rows[i].save();
              this.jugador.VUELOS=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      async rechazar_housing(){
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].HOUSING=1;
              await rows[i].save();
              this.jugador.HOUSING=1;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.notificar();
      },
      notificar(){
        var player = this.jugador['NOMBRE']
        var correo = this.jugador['MAIL']
        var url = "https://t4t.infor.uva.es/email1?mail="+correo+"&name="+player;

        axios
          .get(url)
          .then(res => {
            console.log(res);
            this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN RECHAZADA CON ÉXITO',
              text: 'Se ha mandado el correo de rechazo de información a '+this.jugador.NOMBRE+"."
            });
          })
          .catch(error => {
            console.error(error);
          });
      },
      reclamar(){
        var player = this.jugador['NOMBRE']
        var correo = this.jugador['MAIL']
        var url = "https://t4t.infor.uva.es/email2?mail="+correo+"&name="+player;

        axios
          .get(url)
          .then(res => {
            console.log(res);
            this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN RECLAMADA CON ÉXITO',
              text: 'Se ha mandado el correo de reclamación de información a '+this.jugador.NOMBRE+"."
            });
          })
          .catch(error => {
            console.error(error);
          });

        
      },
      calculaporcentaje(jugador){
            var cuenta = 0;
            var correcto = 0;
            if (jugador.PASAPORTE!=0){
                cuenta=cuenta+1;
                if (jugador.PASAPORTE==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_3_ESO!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_3_ESO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_4_ESO!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_4_ESO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_1_BACH!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_1_BACH==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_2_BACH!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_2_BACH==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.TITULO_BACHILLERATO!=0){
                cuenta=cuenta+1;
                if (jugador.TITULO_BACHILLERATO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.CARTA_DEL_BANCO!=0){
                cuenta=cuenta+1;
                if (jugador.CARTA_DEL_BANCO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REGISTRO_LIGA!=0){
                cuenta=cuenta+1;
                if (jugador.REGISTRO_LIGA==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REGISTRO_UNIVERSIDAD!=0){
                cuenta=cuenta+1;
                if (jugador.REGISTRO_UNIVERSIDAD==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.DUOLINGO!=0){
                cuenta=cuenta+1;
                if (jugador.DUOLINGO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.TOEFL!=0){
                cuenta=cuenta+1;
                if (jugador.TOEFL==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.SAT!=0){
                cuenta=cuenta+1;
                if (jugador.SAT==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.PARTIDOS_COMPLETOS_2!=0){
                cuenta=cuenta+1;
                if (jugador.PARTIDOS_COMPLETOS_2==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.I20!=0){
                cuenta=cuenta+1;
                if (jugador.I20==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.FORMULARIO_ADM!=0){
                cuenta=cuenta+1;
                if (jugador.FORMULARIO_ADM==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.INCRED!=0){
                cuenta=cuenta+1;
                if (jugador.INCRED==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.EVALUACION_DE_LAS_NOTAS!=0){
                cuenta=cuenta+1;
                if (jugador.EVALUACION_DE_LAS_NOTAS==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_CARRERA!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_CARRERA==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REFERENCE_LETTER!=0){
                cuenta=cuenta+1;
                if (jugador.REFERENCE_LETTER==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.SEGURO_MEDICO!=0){
                cuenta=cuenta+1;
                if (jugador.SEGURO_MEDICO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.VUELOS!=0){
                cuenta=cuenta+1;
                if (jugador.VUELOS==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.HOUSING!=0){
                cuenta=cuenta+1;
                if (jugador.HOUSING==3){
                    correcto=correcto+1;
                }
            }

            if (correcto==0){
                return 0;
            }else{
            return parseInt((correcto/cuenta)*100);
            }
        }
    }
  }
</script>
<style scoped lang="scss">
.contenedor1{
  display: flex;
}


.izquierda{
  flex: 1;
}

.derecha{
  flex: 1;
}

.card{
  float: right;
  margin-right: 10px;
}

.card1{
  margin-left: 10px;
}


.boton{
  margin-bottom: 10px;
}

.selector{
  margin-bottom: 15px;
}

.item{
  margin-top: 15px;
}

.linear{
  display: inline-block;
  padding: 10px;
}

.linear1{
  display: inline-block;
}

.flex {
  flex-grow: 0;
}


.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  
  div {
    width: 150px;
  }
}

.contenedor3{
  display: flex;
}


.boton1{
    margin-left: 2%;
}
</style>